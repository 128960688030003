import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  createProperty,
  updatePropertyById,
  getPropertyById,
} from "../../../../services/PropertyService";
import "./propertyform.css"; // Import CSS for styling
import { FaTimes } from "react-icons/fa";

const propertyTypes = ["LAND", "VILLA", "APPARTMENTS"];
const landTypes = ["BDA", "B-KATHA", "A-KATHA", "E-KATHA", "Revenue"];
const sellerTypes = ["Owner", "Reseller", "Reference"];
const facing = [
  "East",
  "West",
  "South",
  "North",
  "Northeast",
  "Northwest",
  "Southeast",
  "Southwest",
];

const PropertyForm = () => {
  const [propertyData, setPropertyData] = useState({
    date: new Date().toISOString().split("T")[0],
    propertyType: "",
    landType: "",
    facing: "",
    measurement: "",
    squareFeetDetails: "",
    pricePerSquareFeet: "",
    roadWidth: 0,
    sellerType: "",
    name: "",
    contactNo: "",
    blackPercentage: "",
    whitePercentage: 0,
    status: "Available",
    comment: "",
    address: "",
  });

  const navigate = useNavigate();
  const { id } = useParams();
  console.log("Extracted id:", id);

  useEffect(() => {
    if (id) {
      console.log("Fetching property data for ID:", id);
      getPropertyById(id)
        .then((response) => {
          console.log("Response from getPropertyById:", response); // Log the entire response object
          console.log("Property data response:", response.data); // Log the data property
          setPropertyData(response);
        })
        .catch((error) =>
          console.error("Error fetching property data:", error)
        );
    }
  }, [id]);
  
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPropertyData({
      ...propertyData,
      [name]: value,
    });
  };

  const navigateToProperties = () => {
    navigate("/home/properties");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (id) {
      updatePropertyById(id, propertyData)
        .then((response) => {
          navigate("/home/properties"); // Redirect to properties list page after successful update
        })
        .catch((error) => {
          console.error("Error updating property:", error);
        });
    } else {
      createProperty(propertyData)
        .then((response) => {
          navigate("/home/properties"); // Redirect to properties list page after successful submission
        })
        .catch((error) => {
          console.error("Error adding property:", error);
        });
    }
  };

  return (
    <div className="property-form-container">
      <div className="addform-content">
        <span className="addform-title">
          {id ? "Edit Property" : "Add Property"}
        </span>
        <button onClick={navigateToProperties} className="header-cancel-button">
          <FaTimes aria-hidden="true" />
        </button>
      </div>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="name">Owner Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={propertyData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="contactNo">Contact No</label>
            <input
              type="text"
              id="contactNo"
              name="contactNo"
              value={propertyData.contactNo}
              onChange={handleChange}
              required
              inputMode="numeric"
              pattern="[0-9]*"
            />
          </div>
          <div className="form-group">
            <label htmlFor="propertyType">Property Type</label>
            <select
              id="propertyType"
              name="propertyType"
              value={propertyData.propertyType}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select Property Type
              </option>
              {propertyTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="landType">Land Type</label>
            <select
              type="text"
              id="landType"
              name="landType"
              value={propertyData.landType}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select Land Type
              </option>
              {landTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="facing">Facing</label>
            <select
              type="text"
              id="facing"
              name="facing"
              value={propertyData.facing}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select Facing
              </option>
              {facing.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="measurement">Measurement</label>
            <input
              type="text"
              id="measurement"
              name="measurement"
              value={propertyData.measurement}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="squareFeetDetails">Square Feet Details</label>
            <input
              type="text"
              id="squareFeetDetails"
              name="squareFeetDetails"
              value={propertyData.squareFeetDetails}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="pricePerSquareFeet">Price Per Square Feet</label>
            <input
              type="text"
              id="pricePerSquareFeet"
              name="pricePerSquareFeet"
              value={propertyData.pricePerSquareFeet}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="roadWidth">Road Width</label>
            <input
              type="number"
              id="roadWidth"
              name="roadWidth"
              value={propertyData.roadWidth}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="sellerType">Seller Type</label>
            <select
              type="text"
              id="sellerType"
              name="sellerType"
              value={propertyData.sellerType}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select Seller Type
              </option>
              {sellerTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="blackPercentage">Black Percentage</label>
            <input
              type="number"
              id="blackPercentage"
              name="blackPercentage"
              value={propertyData.blackPercentage}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="whitePercentage">White Percentage</label>
            <input
              type="number"
              id="whitePercentage"
              name="whitePercentage"
              value={propertyData.whitePercentage}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="status">Status</label>
            <div className="radio-group">
              <label>
                <input
                  type="radio"
                  name="status"
                  value="Available"
                  checked={propertyData.status === "Available"}
                  onChange={handleChange}
                  required
                />
                Available
              </label>
              <label>
                <input
                  type="radio"
                  name="status"
                  value="Sold"
                  checked={propertyData.status === "Sold"}
                  onChange={handleChange}
                />
                Sold
              </label>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="comment">Comment</label>
            <textarea
              type="text"
              id="comment"
              name="comment"
              value={propertyData.comment}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="address">Address</label>
            <textarea
              type="text"
              id="address"
              name="address"
              value={propertyData.address}
              onChange={handleChange}
              required
            />
          </div>
        </div>
       
        <div className="button-container">
          <button type="submit" className="submit-button">
            Save
          </button>
          <button
            type="button"
            className="cancel-button"
            onClick={navigateToProperties}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default PropertyForm;
