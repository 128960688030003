import axios from 'axios';

const API_URL = 'https://api.brahmasamudraproperties.com/api/properties'; // Adjust this URL if necessary

// Create a new property
const createProperty = async (propertyData) => {
    try {
        const response = await axios.post(API_URL, propertyData);
        return response.data;
    } catch (error) {
        console.error('There was an error creating the property!', error);
        throw error;
    }
};

// Get all properties
const getAllProperties = async () => {
    try {
        const response = await axios.get(API_URL);
        return response.data;
    } catch (error) {
        console.error('There was an error fetching the properties!', error);
        throw error;
    }
};

// Get a property by ID
const getPropertyById = async (id) => {
    try {
        console.log(`Fetching property with ID: ${id}`); // Log the ID being used for the request
        const response = await axios.get(`${API_URL}/${id}`);
        console.log("Response data:", response.data); // Log the response data
        return response.data;
    } catch (error) {
        console.error(`There was an error fetching the property with ID ${id}!`, error);
        throw error;
    }
};


// Update a property by ID
const updatePropertyById = async (id, propertyData) => {
    try {
        const response = await axios.put(`${API_URL}/${id}`, propertyData);
        return response.data;
    } catch (error) {
        console.error(`There was an error updating the property with ID ${id}!`, error);
        throw error;
    }
};

// Delete a property by ID
const deletePropertyById = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/${id}`);
        return response.data;
    } catch (error) {
        console.error(`There was an error deleting the property with ID ${id}!`, error);
        throw error;
    }
};

export {
    createProperty,
    getAllProperties,
    getPropertyById,
    updatePropertyById,
    deletePropertyById
};
