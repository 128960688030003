import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import "./DialogBox.css"; // Import the CSS file

const DialogBox = ({ open, title, message, onConfirm, onClose }) => {
  return (
    <div className="container">
      <Dialog  open={open} onClose={onClose}>
        <DialogTitle className="dialog-title">{title}</DialogTitle>
        <DialogContent className="dialog-content">
          <DialogContentText className="dialog-content-text">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button onClick={onClose} className="dialog-button">
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            className="dialog-button-confirm"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogBox;
