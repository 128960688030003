import React, { useState } from "react";
import { FaBars, FaUser, FaBuilding, FaSignOutAlt } from "react-icons/fa";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import "./Dashboard.css";
import titleLogo from "../../assets/Images/title.png";

const Dashboard = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    navigate("/login");
  };

  const navigateToClients = () => {
    navigate("/home/clients");
  };

  const navigateToProperties = () => {
    navigate("/home/properties");
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <div className="dashboard-container">
      <div className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
        <div className="sidebar-header">
          <h2 className={`sidebar-logo ${isSidebarOpen ? "show" : "hide"}`}>
            Dashboard
          </h2>
          <button className="sidebar-toggle" onClick={toggleSidebar}>
            <FaBars />
          </button>
        </div>
        <nav className="sidebar-menu">
          <div
            className={`sidebar-item ${
              isActive("/home/clients") ? "active" : ""
            }`}
            onClick={navigateToClients}
          >
            <FaUser />
            <span className={`menu-text ${isSidebarOpen ? "show" : "hide"}`}>
              Clients
            </span>
          </div>
          <div style={{display : "none"}}
            className={`sidebar-item ${
              isActive("/home/properties") ? "active" : ""
            }`}
            onClick={navigateToProperties}
          >
            <FaBuilding />
            <span className={`menu-text ${isSidebarOpen ? "show" : "hide"}`}>
              Properties
            </span>
          </div>
        </nav>
      </div>
      <div className={`main-content ${isSidebarOpen ? "" : "closed"}`}>
        <header className={`header ${isSidebarOpen ? "" : "closed"}`}>
          <span className="sidebar-toggle" onClick={toggleSidebar}>
            <FaBars />
          </span>
          <span className="header-logo">
          <img
            src={titleLogo}
            alt="Brahmasamudra Properties Logo"            
          />
       </span>
          <span className="logout-button" onClick={handleLogout}>
            <FaSignOutAlt />
          </span>
        </header>
        <div className="content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
