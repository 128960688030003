import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS
import "./RegisterPage.css";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import the icons

const RegisterPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  // const [error] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://api.brahmasamudraproperties.com/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password, email }),
      });

      const data = await response.json();

      if (response.ok) {
        // Display success message and redirect to login page
        toast.success("User registered successfully");
        navigate("/login"); // Redirect to login page
      } else {
        // Display error message
        toast.error(data.message);
      }
    } catch (err) {
      console.error("Registration error:", err);
      toast.error("Server error. Please try again later.");
    }
  };

  return (
    <div className="register-container">
      <div className="registeration-box">
        <form onSubmit={handleSubmit}>
          <h1>Register Page</h1>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <div className="password-input-container">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                className="password-icon"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>
          <button type="submit">Register</button>
        </form>
        <p className="haveAnAccount">
          Already have an account? <a href="/login">Login</a>
        </p>
        <ToastContainer />
      </div>
    </div>
  );
};

export default RegisterPage;
