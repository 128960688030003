import React, { useState, useEffect, useMemo } from "react";
import { getAllProperties, deletePropertyById } from "../../../services/PropertyService";
import { useNavigate } from "react-router-dom";
import { useTable, usePagination, useSortBy, useGlobalFilter } from "react-table";
import { FaEdit, FaTrash, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import DialogBox from "../../common/dialogbox/DialogBox";
import "./Properties.css"; // Import the CSS file

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
    return (
        <div className="global-filter">
            <label htmlFor="search" className="filter-label">
                Search:
            </label>
            <input
                type="text"
                id="search"
                value={globalFilter || ""}
                onChange={(e) => setGlobalFilter(e.target.value)}
                placeholder="Type to search..."
                className="filter-input"
            />
        </div>
    );
};

const Properties = () => {
    const [properties, setProperties] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedPropertyId, setSelectedPropertyId] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        getAllProperties()
            .then((response) => setProperties(response))
            .catch((error) => console.error(error));
    }, []);

    const handleDelete = (id) => {
        setSelectedPropertyId(id);
        setDialogOpen(true);
    };

    const confirmDelete = () => {
        deletePropertyById(selectedPropertyId)
            .then(() => {
                setProperties(properties.filter((property) => property._id !== selectedPropertyId));
                toast.success("Property deleted successfully");
            })
            .catch((error) => {
                console.error(error);
                toast.error("Failed to delete property");
            })
            .finally(() => {
                setDialogOpen(false);
                setSelectedPropertyId(null);
            });
    };

    const handleEdit = (propertyId) => {
        navigate(`/home/propertyform/${propertyId}`);
    };

    const columns = useMemo(
        () => [
            { Header: "Index", accessor: (_, rowIndex) => rowIndex + 1 },
            { Header: "Name", accessor: "name" },
            { Header: "Property Type", accessor: "propertyType" },
            { Header: "Land Type", accessor: "landType" },
            { Header: "Facing", accessor: "facing" },
            { Header: "Measurement", accessor: "measurement" },
            { Header: "Square Feet", accessor: "squareFeetDetails" },
            { Header: "Price", accessor: "pricePerSquareFeet" },
            { Header: "Width", accessor: "roadWidth" },
            {
                Header: "Actions",
                accessor: "_id",
                Cell: ({ row }) => (
                    <div className="actions-cell">
                        <button className="property-editicon" onClick={() => handleEdit(row.original._id)}>
                            <FaEdit />
                        </button>
                        <button className="property-deleteicon" onClick={() => handleDelete(row.original._id)}>
                            <FaTrash />
                        </button>
                    </div>
                ),
            },
        ],
        [properties]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        setPageSize,
    } = useTable(
        {
            columns,
            data: properties || [], // Ensure data is always an array
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const navigateToClientForms = () => {
        navigate("/home/propertyform");
    };

    return (
        <div className="propertys-container">
            <ToastContainer />
            <DialogBox
                open={dialogOpen}
                title="Confirm Deletion"
                message="Are you sure you want to delete this property?"
                onConfirm={confirmDelete}
                onClose={() => setDialogOpen(false)}
            />
            <div className="Add-Client-button-container">
                <button onClick={navigateToClientForms} className="add-property-button">
                    Add Property
                </button>
                <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
            </div>
            <div className="table-container">
                <span className="propertylist-title">Property List</span>
                <div className="property-table">
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render("Header")}
                                            <span>
                                                {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="pagination">
                        <span onClick={() => previousPage()} disabled={!canPreviousPage}>
                            <FaArrowLeft />
                        </span>
                        <span>
                            Page <strong>{pageIndex + 1} of {pageOptions.length}</strong>
                        </span>
                        <span onClick={() => nextPage()} disabled={!canNextPage}>
                            <FaArrowRight />
                        </span>
                        <select
                            value={pageSize}
                            onChange={(e) => setPageSize(Number(e.target.value))}
                        >
                            {[10, 20, 30, 40, 50].map((size) => (
                                <option key={size} value={size}>
                                    {size}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Properties;
