import React, { useState, useEffect, useMemo } from "react";
import { fetchClients, deleteClient } from "../../../services/ClientService";
import { useNavigate } from "react-router-dom";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import { FaEdit, FaTrash, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DialogBox from "../../common/dialogbox/DialogBox";
import "./Clients.css"; // Import the CSS file

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  return (
    <div className="global-filter">
      <label htmlFor="search" className="filter-label">
        Search:
      </label>
      <input
        type="text"
        id="search"
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Type to search..."
        className="filter-input"
      />
    </div>
  );
};

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchClients()
      .then((response) => setClients(response.data))
      .catch((error) => console.error(error));
  }, []);

  const handleDelete = (id) => {
    setSelectedClientId(id);
    setDialogOpen(true);
  };

  const confirmDelete = () => {
    deleteClient(selectedClientId)
      .then(() => {
        setClients(clients.filter((client) => client._id !== selectedClientId));
        toast.success("Client deleted successfully");
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to delete client");
      })
      .finally(() => {
        setDialogOpen(false);
        setSelectedClientId(null);
      });
  };

  const handleEdit = (clientId) => {
    navigate(`/home/clientform/${clientId}`);
  };

  const columns = useMemo(
    () => [
      { Header: "Index", accessor: (_, rowIndex) => rowIndex + 1 },
      { Header: "Client Name", accessor: "ClientName" },
      { Header: "Client Type", accessor: "ClientType" },
      { Header: "Property Type", accessor: "PropertyType" },
      { Header: "Land Type", accessor: "LandType" },
      { Header: "Area", accessor: "Area" },
      { Header: "Budget", accessor: "Budget" },
      { Header: "Status", accessor: "Status" },
      { Header: "Comment", accessor: "Comments" },
      {
        Header: "Actions",
        accessor: "_id",
        Cell: ({ row }) => (
          <div className="actions-cell">
            <button
              className="client-editicon"
              onClick={() => handleEdit(row.original._id)}
            >
              <FaEdit />
            </button>
            <button
              className="client-deleteicon"
              onClick={() => handleDelete(row.original._id)}
            >
              <FaTrash />
            </button>
          </div>
        ),
      },
    ],
    [clients]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    setPageSize,
  } = useTable(
    {
      columns,
      data: clients,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const navigateToClientForms = () => {
    navigate("/home/clientform");
  };

  return (
    <div className="clients-container">
      <ToastContainer />
      <DialogBox
        open={dialogOpen}
        title="Confirm Deletion"
        message="Are you sure you want to delete this client?"
        onConfirm={confirmDelete}
        onClose={() => setDialogOpen(false)}
      />
      <div className="Add-Client-button-container">
        <button onClick={navigateToClientForms} className="add-client-button">
          Add Client
        </button>
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
      <div className="table-container">
        <span className="clientlist-title">Client List</span>
        <div className="client-table">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="pagination">
            <span onClick={() => previousPage()} disabled={!canPreviousPage}>
              <FaArrowLeft />
            </span>
            <span className="page-info">
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
            <span onClick={() => nextPage()} disabled={!canNextPage}>
              <FaArrowRight />
            </span>
            <select
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[10, 20, 30, 40, 50].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
