import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './components/dashboard/Dashboard';
import Clients from './components/dashboard/clients/Clients'; // Adjust the import path
import Properties from './components/dashboard/properties/Properties'; // Adjust the import path
import ClientForm from './components/dashboard/clients/clientform/clientform';
import PropertyForm from './components/dashboard/properties/propertyform/propertyform';
//import HomePage from './pages/home/HomePage';
import LoginPage from './pages/login/LoginPage';
import RegisterPage from './pages/register/RegisterPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/home/*" element={<Dashboard />}>
          <Route path="clients" element={<Clients />} />
          <Route path="properties" element={<Properties />} />
          <Route path="clientform" element={<ClientForm />} />
          <Route path="clientform/:id" element={<ClientForm />} />
          <Route path="propertyform" element={<PropertyForm />} />
          <Route path="propertyform/:id" element={<PropertyForm />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
