import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addClient,
  updateClient,
  getUsers,
  getClientById,
} from "../../../../services/ClientService";
import "./clientform.css";
import { FaTimes } from "react-icons/fa";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const defaultIcon = new L.Icon({
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
  shadowSize: [41, 41],
});

const ClientForm = () => {
  const [clientData, setClientData] = useState({
    ClientName: "",
    ContactNo: "",
    PropertyType: "",
    LandType: "",
    Facing: "",
    Area: "",
    Budget: "",
    BlackPercentage: 0,
    WhitePercentage: 0,
    ClientType: "",
    ReferenceName: "",
    ReferenceNo: "",
    NoOfSiteVisit: 0,
    ExecutiveId: "",
    Comments: "",
    Measurement: "",
    Squarefeet: "",
    PricePerSquareFeet: "",
    RoadWidth: 0,
    City: "",
    Status: "",
    Address: "",
    EnquireDate: "",
    Latitude: "",
    Longitude: "",
  });

  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const { id } = useParams();
  const [position, setPosition] = useState([12.9716, 77.5946]); // Default position (Bangalore)
  const [pinPosition, setPinPosition] = useState(null);
  const [address, setAddress] = useState("");
  const mapRef = useRef(null); // Create a ref for the map instance

  const propertyTypes = [
    "LAND",
    "VILLA",
    "APPARTMENTS",
    "INDUSTRIAL",
    "COMMERCIAL",
    "SITE",
  ];
  const landTypes = [
    "BDA",
    "B-KATHA",
    "A-KATHA",
    "E-KATHA",
    "REVENUE",
    "AGRICULTURE",
    "CONVERTED",
    "MOODA",
  ];
  const clientTypes = ["BUYER", "SELLER", "AGENT"];
  const facing = ["EAST", "WEST", "SOUTH", "NORTH" , "SOUTHEAST","SOUTHWEST","NORTHEAST","NORTHWEST"];
  const statuses = ["WORK IN PROGRESS", "ON HOLD", "OPEN", "DEAL"];

  useEffect(() => {
    getUsers()
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => console.error("Error fetching user data:", error));
  }, []);

  useEffect(() => {
    if (id) {
      getClientById(id)
        .then((response) => {
          setClientData(response.data);
          const { Latitude, Longitude } = response.data;
          if (Latitude && Longitude) {
            setPosition([Latitude, Longitude]);
            setPinPosition([Latitude, Longitude]);
            fetch(
              `https://nominatim.openstreetmap.org/reverse?format=json&lat=${Latitude}&lon=${Longitude}&addressdetails=1`
            )
              .then((response) => response.json())
              .then((data) => setAddress(data.display_name))
              .catch((error) =>
                console.error("Error fetching address:", error)
              );
          }
        })
        .catch((error) => console.error("Error fetching client data:", error));
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (
      name === "BlackPercentage" ||
      name === "WhitePercentage" ||
      name === "NoOfSiteVisit" ||
      name === "RoadWidth"
    ) {
      const numericValue = parseInt(value, 10);
      setClientData({
        ...clientData,
        [name]: isNaN(numericValue) ? 0 : numericValue,
      });
    } else if (name === "ContactNo" || name === "ReferenceNo") {
      const numericValue = value.replace(/[^0-9]/g, "");
      setClientData({
        ...clientData,
        [name]: numericValue,
      });
    } else {
      setClientData({
        ...clientData,
        [name]: value,
      });
    }
  };

  const navigateToClients = () => {
    navigate("/home/clients");
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setPosition([latitude, longitude]);
          setPinPosition([latitude, longitude]);

          if (mapRef.current) {
            const map = mapRef.current.leafletElement;
            map.setView([latitude, longitude], 13);
          }

          fetch(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1`
          )
            .then((response) => response.json())
            .then((data) => {
              setAddress(data.display_name);
              setClientData({
                ...clientData,
                Address: data.display_name,
              });
            })
            .catch((error) => console.error("Error fetching address:", error));
        },
        (error) => {
          console.error("Error fetching current location:", error);
        },
        { enableHighAccuracy: true }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleMapClick = (event) => {
    const { latlng } = event;
    const { lat, lng } = latlng;
    setPinPosition([lat, lng]);

    fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&addressdetails=1`
    )
      .then((response) => response.json())
      .then((data) => {
        setAddress(data.display_name);
        setClientData({
          ...clientData,
          Address: data.display_name,
        });
      })
      .catch((error) => console.error("Error fetching address:", error));
  };

  const handleMarkerDrag = (event) => {
    const { latlng } = event;
    if (latlng) {
      const { lat, lng } = latlng;
      setPinPosition([lat, lng]);

      fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&addressdetails=1`
      )
        .then((response) => response.json())
        .then((data) => {
          setAddress(data.display_name);
          setClientData({
            ...clientData,
            Address: data.display_name,
            Latitude: lat,
            Longitude: lng,
          });
        })
        .catch((error) => console.error("Error fetching address:", error));
    } else {
      console.error("LatLng is undefined in handleMarkerDrag");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedData = {
      ...clientData,
      Latitude: pinPosition ? pinPosition[0] : position[0],
      Longitude: pinPosition ? pinPosition[1] : position[1],
    };

    if (id) {
      updateClient(id, updatedData)
        .then(() => {
          navigate("/home/clients");
        })
        .catch((error) => {
          console.error("Error updating client:", error);
        });
    } else {
      console.log("Add client", updatedData)
      addClient(updatedData)
        .then(() => {
          navigate("/home/clients");
        })
        .catch((error) => {
          console.error("Error adding client:", error);
        });
    }
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
  
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  };


  return (
    <div className="client-form-container">
      <div className="addform-content">
        <span className="addform-title">
          {id ? "Edit Client" : "Add Client"}
        </span>
        <button onClick={navigateToClients} className="header-cancel-button">
          <FaTimes aria-hidden="true" />
        </button>
      </div>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="ClientType">Client Type</label>
            <select
              id="ClientType"
              name="ClientType"
              value={clientData.ClientType}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select Client Type
              </option>
              {clientTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="ClientName">Client Name</label>
            <input
              type="text"
              id="ClientName"
              name="ClientName"
              value={clientData.ClientName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="ContactNo">Contact No</label>
            <input
              type="text"
              id="ContactNo"
              name="ContactNo"
              value={clientData.ContactNo}
              onChange={handleChange}             
              inputMode="numeric"
              pattern="[0-9]*"
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="PropertyType">Property Type</label>
            <select
              id="PropertyType"
              name="PropertyType"
              value={clientData.PropertyType}
              onChange={handleChange}            
            >
              <option value="" disabled>
                Select Property Type
              </option>
              {propertyTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="LandType">Land Type</label>
            <select
              id="LandType"
              name="LandType"
              value={clientData.LandType}
              onChange={handleChange}             
            >
              <option value="" disabled>
                Select Land Type
              </option>
              {landTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="Facing">Facing</label>
            <select
              id="Facing"
              name="Facing"
              value={clientData.Facing}
              onChange={handleChange}             
            >
              <option value="" disabled>
                Select Facing
              </option>
              {facing.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="Area">Area</label>
            <input
              type="text"
              id="Area"
              name="Area"
              value={clientData.Area}
              onChange={handleChange}            
            />
          </div>
          <div className="form-group">
            <label htmlFor="Measurement">Measurement</label>
            <input
              type="text"
              id="Measurement"
              name="Measurement"
              value={clientData.Measurement}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="Squarefeet">Square Feet</label>
            <input
              type="text"
              id="Squarefeet"
              name="Squarefeet"
              value={clientData.Squarefeet}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="PricePerSquareFeet">Price Per Square Feet</label>
            <input
              type="text"
              id="PricePerSquareFeet"
              name="PricePerSquareFeet"
              value={clientData.PricePerSquareFeet}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="RoadWidth">Road Width</label>
            <input
              type="number"
              id="RoadWidth"
              name="RoadWidth"
              value={clientData.RoadWidth}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="Budget">Budget</label>
            <input
              type="text"
              id="Budget"
              name="Budget"
              value={clientData.Budget}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="BlackPercentage">Black Percentage</label>
            <input
              type="number"
              id="BlackPercentage"
              name="BlackPercentage"
              value={clientData.BlackPercentage}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="WhitePercentage">White Percentage</label>
            <input
              type="number"
              id="WhitePercentage"
              name="WhitePercentage"
              value={clientData.WhitePercentage}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="ReferenceName">Reference Name</label>
            <input
              type="text"
              id="ReferenceName"
              name="ReferenceName"
              value={clientData.ReferenceName}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="ReferenceNo">Reference No</label>
            <input
              type="text"
              id="ReferenceNo"
              name="ReferenceNo"
              value={clientData.ReferenceNo}
              onChange={handleChange}
              inputMode="numeric"
              pattern="[0-9]*"
            />
          </div>
          <div className="form-group">
            <label htmlFor="NoOfSiteVisit">No Of Site Visit</label>
            <input
              type="number"
              id="NoOfSiteVisit"
              name="NoOfSiteVisit"
              value={clientData.NoOfSiteVisit}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="ExecutiveId">Executive</label>
            <select
              id="ExecutiveId"
              name="ExecutiveId"
              value={clientData.ExecutiveId}
              onChange={handleChange} 
              required            
            >
              <option value="" disabled>
                Select Executive
              </option>
              {users.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.username}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="City">City</label>
            <input
              type="text"
              id="City"
              name="City"
              value={clientData.City}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="Status">Status</label>
            <select
              id="Status"
              name="Status"
              value={clientData.Status}
              onChange={handleChange}            
            >
              <option value="" disabled>
                Select Status
              </option>
              {statuses.map((status) => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="EnquireDate">Enquire Date</label>
            <input
              type="date"
              id="EnquireDate"
              name="EnquireDate"
              value={formatDateForInput(clientData.EnquireDate)}
              onChange={handleChange}             
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="Address">Address</label>
            <input
              type="text"
              id="Address"
              name="Address"
              value={clientData.Address}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="Comments">Comments</label>
            <textarea
              id="Comments"
              name="Comments"
              value={clientData.Comments}
              onChange={handleChange}
            />
          </div>
        </div>

        <MapContainer
          center={position}
          zoom={13}
          style={{ height: "400px", width: "100%" ,display:"none"}}
          whenCreated={(map) => {
            mapRef.current = map;
          }}
          onClick={handleMapClick}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {pinPosition && (
            <Marker
              position={pinPosition}
              icon={defaultIcon}
              draggable={true}
              eventHandlers={{
                dragend: handleMarkerDrag,
              }}
            >
              <Popup>{address}</Popup>
            </Marker>
          )}
        </MapContainer>
        <button type="button" onClick={getCurrentLocation}>
          Use Current Location
        </button>


        <div className="button-container">
          <button type="submit" className="submit-button">
            Save
          </button>
          <button
            type="button"
            className="cancel-button"
            onClick={navigateToClients}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClientForm;
