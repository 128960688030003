import axios from 'axios';

const API_URL = 'https://api.brahmasamudraproperties.com/api/clients';

//const API_URL = 'http://localhost:5000/api/clients';

//export const getUsers = () => axios.get('http://localhost:5000/api/users');   

export const getUsers = async () => {
    try {
      const response = await axios.get('https://api.brahmasamudraproperties.com/api/users');
      return response;
    } catch (error) {
      console.error("Error in getUsers service:", error); // Debugging step
      throw error;
    }
  };
export const fetchClients = () => axios.get(API_URL);
export const addClient = async (client) => {
  try {
      console.log('Sending request with client data:', client);

      const response = await axios.post(API_URL, client);

      console.log('Response from server:', response.data);

      return response.data;
  } catch (error) {
      if (error.response) {
          // Request made and server responded with a status code
          // that falls out of the range of 2xx
          console.error('Response error:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
      } else if (error.request) {
          // The request was made but no response was received
          console.error('Request error:', error.request);
      } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error message:', error.message);
      }
      throw error; // Re-throw the error if needed
  }
};
export const updateClient = (id, client) => axios.put(`${API_URL}/${id}`, client);
export const deleteClient = (id) => axios.delete(`${API_URL}/${id}`);
export const getClientById = (id) => axios.get(`${API_URL}/${id}`);

